import React, { useState } from "react";
import { Stack } from "@chakra-ui/react";

import { CenterBox, FormInput, FormButton, FormLink } from "@components";

export const RegisterPage: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleRegister = async () => {};

  return (
    <CenterBox>
      <Stack spacing={4} width="100%">
        <FormInput
          placeholder="Nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FormInput
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormInput
          placeholder="Telefone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <FormInput
          placeholder="Idade"
          value={age}
          onChange={(e) => setAge(e.target.value)}
        />
        <FormInput
          placeholder="Senha"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <FormInput
          placeholder="Confirme a Senha"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <FormButton onClick={handleRegister} text="Cadastrar" />
      </Stack>
      <FormLink to="/" text="Já tem uma Conta?" />
      <FormLink to="/forgot-password" text="Esqueceu a Senha?" />
    </CenterBox>
  );
};
