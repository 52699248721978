import React from "react";
import { Text, Button } from "@chakra-ui/react";

export const Integrations: React.FC = () => {
  return (
    <>
      <Text fontWeight="bold">Integrações</Text>
      <Button colorScheme="blue">Integrar com Google</Button>
      <Button colorScheme="blue">Integrar com Twitter</Button>
      <Button colorScheme="blue">Integrar com Facebook</Button>
    </>
  );
};
