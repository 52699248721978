import React, { useState } from "react";

import { CenterBox, FormInput, FormButton, Logo } from "@components";

export const ResetPasswordPage: React.FC = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleResetPassword = async () => {
    // Lógica de redefinição de senha
  };

  return (
    <CenterBox>
      <Logo />
      <FormInput
        placeholder="Nova Senha"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <FormInput
        placeholder="Confirme a Nova Senha"
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <FormButton onClick={handleResetPassword} text="Redefinir Senha" />
    </CenterBox>
  );
};
