import React, { useState } from "react";
import { HStack, IconButton, useDisclosure } from "@chakra-ui/react";
import { EditIcon, LockIcon, TimeIcon, SettingsIcon } from "@chakra-ui/icons";

import {
  UserProfileInfo,
  EditProfileForm,
  EmailUpdateModal,
  SecurityForm,
  AccessHistory,
  Integrations,
} from "../components";
import { CenterBox } from "@components/form";

export const ProfilePage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("profile");
  const [email, setEmail] = useState("usuario@dominio.com");
  const [age, setAge] = useState("25");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCodeSent, setIsCodeSent] = useState(false);

  const handleSendCode = () => {
    setIsCodeSent(true);
  };

  const handleCloseModal = () => {
    setIsCodeSent(false);
    onClose();
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "editProfile":
        return (
          <EditProfileForm
            email={email}
            setEmail={setEmail}
            age={age}
            setAge={setAge}
            onOpen={onOpen}
          />
        );
      case "security":
        return <SecurityForm />;
      case "history":
        return <AccessHistory onOpen={onOpen} />;
      case "integrations":
        return <Integrations />;
      default:
        return null;
    }
  };

  const iconButtonProps = (tab: string) => ({
    variant: selectedTab === tab ? "solid" : "outline",
    colorScheme: selectedTab === tab ? "blue" : "gray",
    onClick: () => setSelectedTab(tab),
    _hover: { colorScheme: "blue" },
  });

  return (
    <CenterBox>
      <UserProfileInfo
        name="Nome do Usuário"
        email={email}
        age={age}
        avatarSrc="link-para-imagem-do-usuario"
      />
      <HStack spacing={4} mt={2} mb={2}>
        <IconButton
          aria-label="Editar Perfil"
          icon={<EditIcon />}
          {...iconButtonProps("editProfile")}
        />
        <IconButton
          aria-label="Segurança"
          icon={<LockIcon />}
          {...iconButtonProps("security")}
        />
        <IconButton
          aria-label="Histórico de Acesso"
          icon={<TimeIcon />}
          {...iconButtonProps("history")}
        />
        <IconButton
          aria-label="Integrações"
          icon={<SettingsIcon />}
          {...iconButtonProps("integrations")}
        />
      </HStack>
      {renderContent()}
      <EmailUpdateModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        email={email}
        setEmail={setEmail}
        isCodeSent={isCodeSent}
        handleSendCode={handleSendCode}
      />
    </CenterBox>
  );
};
