import React from "react";
import { Box, VStack, Text, Avatar } from "@chakra-ui/react";

interface UserProfileInfoProps {
  name: string;
  email: string;
  age: string;
  avatarSrc: string;
}

export const UserProfileInfo: React.FC<UserProfileInfoProps> = ({
  name,
  email,
  age,
  avatarSrc,
}) => {
  return (
    <Box>
      <VStack>
        <Avatar size="2xl" name="Usuario" src="link-para-imagem-do-usuario" />
        <Text fontSize="2xl" fontWeight="bold">
          Nome do Usuário
        </Text>
        <Text>{email}</Text>
      </VStack>
    </Box>
  );
};
