import React from "react";
import { Box, VStack } from "@chakra-ui/react";

export const CenterBox: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      bg="white"
      p={8}
      rounded="md"
      boxShadow="lg"
      width="400px"
      maxWidth="100%"
      mx="auto"
    >
      <VStack spacing={4}>{children}</VStack>
    </Box>
  );
};
