import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@chakra-ui/react";

export const FormLink: React.FC<{ to: string; text: string }> = ({
  to,
  text,
}) => {
  return (
    <Link as={RouterLink} to={to}>
      {text}
    </Link>
  );
};
