import React from "react";
import { HStack, IconButton, Text } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";

import { FormInput } from "@components";

interface EditProfileFormProps {
  email: string;
  setEmail: (email: string) => void;
  age: string;
  setAge: (age: string) => void;
  onOpen: () => void;
}

export const EditProfileForm: React.FC<EditProfileFormProps> = ({
  email,
  setEmail,
  age,
  setAge,
  onOpen,
}) => {
  return (
    <>
      <Text fontWeight="bold">Editar Perfil</Text>
      <HStack>
        <FormInput
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <IconButton
          aria-label="Editar email"
          icon={<EditIcon />}
          onClick={onOpen}
        />
      </HStack>
      <FormInput
        placeholder="Idade"
        value={age}
        onChange={(e) => setAge(e.target.value)}
      />
    </>
  );
};
