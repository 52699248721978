import React from "react";
import {
  Box,
  VStack,
  Text,
  HStack,
  Button,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import {
  CheckIcon,
  CloseIcon,
  AtSignIcon,
  TimeIcon,
  InfoIcon,
} from "@chakra-ui/icons";

interface AccessHistoryProps {
  onOpen: () => void;
}

const mockData = [
  {
    ip: "192.168.0.1",
    location: "São Paulo, Brasil",
    dateTime: "22/06/2024 15:30",
  },
  {
    ip: "192.168.0.2",
    location: "Rio de Janeiro, Brasil",
    dateTime: "22/06/2024 14:30",
  },
  {
    ip: "192.168.0.3",
    location: "Curitiba, Brasil",
    dateTime: "22/06/2024 13:30",
  },
];

export const AccessHistory: React.FC<AccessHistoryProps> = ({ onOpen }) => {
  return (
    <>
      <Text fontWeight="bold">Histórico de Acesso</Text>
      {mockData.map((item, index) => (
        <Box key={index} borderWidth="1px" borderRadius="lg" p={4} width="100%">
          <HStack justifyContent="space-between" width="100%">
            <VStack align="start" spacing={2} flex="1">
              <Tooltip label="IP">
                <HStack>
                  <Icon as={AtSignIcon} />
                  <Text>{item.ip}</Text>
                </HStack>
              </Tooltip>
              <Tooltip label="Localização">
                <HStack>
                  <Icon as={InfoIcon} />
                  <Text>{item.location}</Text>
                </HStack>
              </Tooltip>
              <Tooltip label="Data e Hora">
                <HStack>
                  <Icon as={TimeIcon} />
                  <Text>{item.dateTime}</Text>
                </HStack>
              </Tooltip>
            </VStack>
            <HStack>
              <Tooltip label="Reconhecer">
                <Button size="sm" colorScheme="green" variant="outline">
                  <Icon as={CheckIcon} />
                </Button>
              </Tooltip>
              <Tooltip label="Negar">
                <Button
                  size="sm"
                  colorScheme="red"
                  variant="outline"
                  onClick={onOpen}
                >
                  <Icon as={CloseIcon} />
                </Button>
              </Tooltip>
            </HStack>
          </HStack>
        </Box>
      ))}
    </>
  );
};
