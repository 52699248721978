import React from "react";
import { Button } from "@chakra-ui/react";

export const FormButton: React.FC<{
  onClick: () => void;
  text: string;
  bgColor?: string;
}> = ({ onClick, text, bgColor = "#333333" }) => {
  return (
    <Button onClick={onClick} bg={bgColor} color="white" width="100%">
      {text}
    </Button>
  );
};
