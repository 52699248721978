import { Image } from "@chakra-ui/react";

export const Logo: React.FC = () => {
  return (
    <Image
      src="https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg"
      alt="Logo"
      width="150px"
      height="auto"
      mb={4}
    />
  );
};
