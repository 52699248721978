import React from "react";
import { Text } from "@chakra-ui/react";

import { FormInput, FormButton } from "@components";

export const SecurityForm: React.FC = () => {
  return (
    <>
      <Text fontWeight="bold">Alterar Senha</Text>
      <FormInput
        placeholder="Senha Atual"
        type="password"
        value=""
        onChange={() => {}}
      />
      <FormInput
        placeholder="Nova Senha"
        type="password"
        value=""
        onChange={() => {}}
      />
      <FormInput
        placeholder="Confirme a Nova Senha"
        type="password"
        value=""
        onChange={() => {}}
      />
      <FormButton
        text="Salvar Alterações"
        onClick={() => {
          /* lógica para salvar */
        }}
      />
    </>
  );
};
