import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  HStack,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";

interface EmailUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  setEmail: (email: string) => void;
  isCodeSent: boolean;
  handleSendCode: () => void;
}

export const EmailUpdateModal: React.FC<EmailUpdateModalProps> = ({
  isOpen,
  onClose,
  email,
  setEmail,
  isCodeSent,
  handleSendCode,
}) => {
  const [code, setCode] = useState("");

  const handleChangeCode = (value: string) => {
    setCode(value);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Atualizar Email</ModalHeader>
        <ModalBody>
          {isCodeSent ? (
            <HStack>
              <PinInput value={code} onChange={handleChangeCode}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          ) : (
            <Input
              placeholder="Novo Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          )}
        </ModalBody>
        <ModalFooter>
          {isCodeSent ? (
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                // lógica para confirmar o código
                setCode("");
                onClose();
              }}
              isDisabled={code.length < 6}
            >
              Confirmar
            </Button>
          ) : (
            <Button colorScheme="blue" mr={3} onClick={handleSendCode}>
              Enviar Código
            </Button>
          )}
          <Button onClick={onClose}>Cancelar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
