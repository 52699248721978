import React, { useState } from "react";

import { CenterBox, FormInput, FormButton, FormLink, Logo } from "@components";

export const ForgotPasswordPage: React.FC = () => {
  const [email, setEmail] = useState("");

  const handleForgotPassword = async () => {};

  return (
    <CenterBox>
      <Logo />
      <FormInput
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <FormButton
        onClick={handleForgotPassword}
        text="Enviar Link de Redefinição"
      />
      <FormLink to="/" text="Lembrou a Senha?" />
      <FormLink to="/register" text="Cadastre-se!" />
    </CenterBox>
  );
};
