import { Route, Routes } from "react-router-dom";

import { LoginPage } from "@modules/login";
import { ForgotPasswordPage, ResetPasswordPage } from "@modules/password";
import { ProfilePage } from "@modules/profile";
import { RegisterPage } from "@modules/register";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/profile" element={<ProfilePage />} />
    </Routes>
  );
}

export default App;
