import React from "react";
import { Input } from "@chakra-ui/react";

export const FormInput: React.FC<{
  placeholder: string;
  type?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ placeholder, type = "text", value, onChange }) => {
  return (
    <Input
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={onChange}
    />
  );
};
