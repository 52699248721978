import React, { useState } from "react";
import { Text } from "@chakra-ui/react";

import { CenterBox, FormInput, FormButton, FormLink, Logo } from "@components";

export const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async () => {
    try {
      document.cookie = "token=valid-token; path=/";
      window.location.href = "https://chat.kanbom.app";
    } catch (e) {
      setError("Invalid email or password");
    }
  };

  return (
    <CenterBox>
      <Logo />
      <FormInput
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <FormInput
        placeholder="Senha"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {error && <Text color="red.500">{error}</Text>}
      <FormButton onClick={handleLogin} text="Entrar" />
      <FormLink to="/forgot-password" text="Esqueceu a Senha?" />
      <FormLink to="/register" text="Cadastre-se!" />
    </CenterBox>
  );
};
